import styles from "./ActionBtnCard.module.scss";
import DeleteIcon from "components/UI/Icons/Delete";
import EditIcon from "components/UI/Icons/Lines/Edit";
import AFKIcon from "components/UI/Icons/Status/AFK";
import AvailableIcon from "components/UI/Icons/Status/Available";
import AwayIcon from "components/UI/Icons/Status/Away";
import CalenderIcon from "components/UI/Icons/Status/Calender";
import DNDIcon from "components/UI/Icons/Status/DND";
import HolidayIcon from "components/UI/Icons/Status/Holiday";
import OnCallIcon from "components/UI/Icons/Status/OnCall";
import OnLunchIcon from "components/UI/Icons/Status/OnLunch";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { editSidecarAction, removeSidecarAction, updateShowStatusMenu } from "redux/sidecar/sidecarSlice";

const ActionBtnCard = ({
	name,
	extension,
	actionCount,
	uuid,
	deleteConfirmation,
	status,
	allExtensions
}: {
	name: string;
	extension?: string;
	actionCount: string;
	uuid: string;
	deleteConfirmation: ({ actionName, uuid }: { actionName: string; uuid: string }) => void;	
	status:any;
	allExtensions:any;
}) => {
	const [editHover, setEditHover] = useState(false);
	const [deleteHover, setDeleteHover] = useState(false);
	const dispatch = useDispatch();
	const [statusResult, setStatusResult] = useState<any>();
	const [extensionUserId, setExtensionUserId] = useState<any>();
	const [additionalStatusResult, setAdditionalStatusResult] = useState<any>();
	const {extensionStatusChanged} = useSelector((state: any) => state.sidecar);
	useEffect(()=>{
		for(let x = 0; x < allExtensions?.length; x++){
			if(String(allExtensions[x]?.data?.extension) === extension){
				if(allExtensions[x]?.user?.id)
					setExtensionUserId(allExtensions[x]?.user?.id)
				break;
			}
		}
	},[allExtensions])
	useEffect(()=>{
		const filteredStatus = status?.filter((item: { user_id: any; })=>{return (item?.user_id && item?.user_id === extensionUserId) ? true: false })
		setStatusResult(filteredStatus && filteredStatus[0]?.main_status?.status ? String(filteredStatus[0]?.main_status?.status) : null)
		setAdditionalStatusResult(filteredStatus && filteredStatus[0]?.additional_status?.status ? String(filteredStatus[0]?.additional_status?.status) : null)
	},[extensionUserId, status])
	useEffect(() => {
		const extensionStatus = extensionStatusChanged?.filter((item:any)=>{return item?.user_id === extensionUserId ? true : false})
		if(extensionStatus.length){
			const newStatus = extensionStatus[0];
			setStatusResult(newStatus?.main_status?newStatus?.main_status:"")
			setAdditionalStatusResult(newStatus?.additional_status?newStatus?.additional_status:"")
		}
	}, [extensionStatusChanged, extensionUserId]);
	const updateStatus = ()=> {
		const userStatus = {
			userID:extensionUserId,
			main_status: statusResult,
			additional_status: additionalStatusResult,
		}
		dispatch(updateShowStatusMenu({setStatusByUser:true, userStatus:userStatus}))
	}
	return (
		<tr className={styles.actionBtn}>
			<td className={styles.actionName}>{name}</td>
			<td className={styles.actionExtension}>
				<span onClick={updateStatus}>
					<span className={styles.status}>
					{ statusResult === "available" && <AvailableIcon />}
					{ statusResult === "away" && <AwayIcon />}
					{ statusResult === "do_not_disturb" && <DNDIcon />}	
					</span>
					{extension}
					{ additionalStatusResult === "on_a_call" && <OnCallIcon />}
					{ additionalStatusResult === "in_a_meeting" && <CalenderIcon />}
					{ additionalStatusResult === "lunch" && <OnLunchIcon />}
					{ additionalStatusResult === "holiday" && <HolidayIcon />}
					{ additionalStatusResult === "afk" && <AFKIcon />}
				</span>
			</td>
			<td className={styles.actions}>
				<span>
					<span>Actions: </span>
					<span>{actionCount}</span>
				</span>
			</td>
			<td className={styles.actionButton}>
				<span>
					<span
						onMouseOver={() => {
							setEditHover(true);
						}}
						onMouseOut={() => {
							setEditHover(false);
						}}
						onClick={() => {
							dispatch(editSidecarAction(uuid));
						}}>
						<EditIcon color={editHover ? "primary-default" : "icon-primary"} />
					</span>
					<span
						onMouseOver={() => {
							setDeleteHover(true);
						}}
						onMouseOut={() => {
							setDeleteHover(false);
						}}
						onClick={() => {
							deleteConfirmation({ actionName: name, uuid: uuid });
						}}>
						<DeleteIcon color={deleteHover ? "support-danger-default" : "icon-primary"} />
					</span>
				</span>
			</td>
		</tr>
	);
};

export default ActionBtnCard;
