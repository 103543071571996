import { useEffect, useRef, useState } from "react";
import styles from "./ExtensionCard.module.scss";
import CalenderIcon from "components/UI/Icons/Status/Calender";
import BtnAction from "components/UI/BtnAction";
import PhoneIcon from "components/UI/Icons/Fills/Phone";
import sip from "lib/sip";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { setShowSidecarSidebar, updateShowStatusMenu } from "redux/sidecar/sidecarSlice";
import EditPenIcon from "components/UI/Icons/Lines/EditPenIcon";
import CallerReadyIcon from "components/UI/Icons/Lines/CallerReadyIcon";
import OnCallIcon from "components/UI/Icons/Status/OnCall";
import HolidayIcon from "components/UI/Icons/Status/Holiday";
import AFKIcon from "components/UI/Icons/Status/AFK";
import OnLunchIcon from "components/UI/Icons/Status/OnLunch";
import { IPresence } from "redux/sidecar/sidecarTypes";
import CallerInboundIcon from "components/UI/Icons/Lines/CallerInboundIcon";
import CallerOutboundIcon from "components/UI/Icons/Lines/CallerOutboundIcon";
import Logout from "components/UI/Icons/Sidecar/Logout";
import { useLazyQueueLoginQuery, useLazyQueueLogoutQuery } from "services/queues";
import { showToast } from "utils";
import HangupIcon from "components/UI/Icons/Sidecar/Hangup";
import TransferIcon from "components/UI/Icons/Lines/Transfer";
import Transfer2Icon from "components/UI/Icons/Lines/Transfer2";
import TransferCall from "components/Dashboard/TransferCall";
import CallTransferIcon from "components/UI/Icons/Call/CallTransfer";
import TransferCallCard from "components/Dashboard/TransferCallCard";

const ExtensionCard = ({
	queueID,
	queue,
	extensionData,
	status,
}: {
	queueID?: string;
	queue?: any;
	extensionData: any;
	status: any;
}) => {
	const [isCallHover, setIsCallHover] = useState(false);
	const [isCardHover, setIsCardHover] = useState(false);
	const [showTransferBtn, setTransferBtn] = useState(false);
	const [isCallTransferHover, setIsCallTransferHover] = useState(false);
	const [statusResult, setStatusResult] = useState<any>();
	const [additionalStatusResult, setAdditionalStatusResult] = useState<any>();
	const { extensionPresenceChanged } = useSelector((state: any) => state.sidecar);
	const { extensionStatusChanged } = useSelector((state: any) => state.sidecar);
	const [callStartTimeHHMMSS, setCallStartTimeHHMMSS] = useState({ callStartTime1: "", callStartTime2: "" });
	const [callLines, setCallLines] = useState<IPresence>({ call_lines: [], extension_id: "", status: "" });
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [queueLogout] = useLazyQueueLogoutQuery();
	const { answeredCalls, ringingOutboundCalls, ringingInboundCalls } = useSelector((state: any) => state.sip);

	const durationTimmer = useRef(false);
	const handleCall = () => {
		if (extensionData?.extension) {
			sip.call(String(extensionData?.extension));
			dispatch(setShowSidecarSidebar(false));
			navigate("/dashboard");
		}
	};
	const filteredStatus = status?.filter((item: { user_id: any }) => {
		return item?.user_id && item?.user_id === extensionData?.user?.id ? true : false;
	});
	const line1 = callLines?.call_lines && callLines?.call_lines[0] ? callLines?.call_lines[0]?.callerid : "";
	const line2 = callLines?.call_lines && callLines?.call_lines[1] ? callLines?.call_lines[1]?.callerid : "";
	const convertToHHMMSS = (unix_timestamp: number) => {
		if (!unix_timestamp) {
			return "";
		}
		let date = new Date(unix_timestamp * 1000);
		let now = new Date();
		let durationInSeconds = Math.floor((now.getTime() - date.getTime()) / 1000);
		let durationInMinutes = Math.floor(durationInSeconds / 60);
		let durationInHours = Math.floor(durationInMinutes / 60);

		const padZero = (number: number) => {
			return number < 10 ? "0" + number : number;
		};

		return `${padZero(durationInHours % 24)}:${padZero(durationInMinutes % 60)}:${padZero(durationInSeconds % 60)}`;
	};
	useEffect(() => {
		setStatusResult(
			filteredStatus && filteredStatus[0]?.main_status?.status ? String(filteredStatus[0]?.main_status?.status) : null,
		);
		setAdditionalStatusResult(
			filteredStatus && filteredStatus[0]?.additional_status?.status
				? String(filteredStatus[0]?.additional_status?.status)
				: null,
		);
	}, []);
	useEffect(() => {
		setCallLines({
			call_lines: extensionData?.presence_call_lines ? [...extensionData?.presence_call_lines] : [],
			extension_id: extensionData?.subscriber?.extension_id,
			status: extensionData?.presence_status,
		});
	}, []);
	useEffect(() => {
		if (durationTimmer.current === true) {
			return;
		}
		const intervalId = setInterval(() => {
			durationTimmer.current = true;
			if (!(callLines?.call_lines && callLines?.call_lines[0])) {
				durationTimmer.current = false;
				clearInterval(intervalId);
				setCallStartTimeHHMMSS({ callStartTime1: "", callStartTime2: "" });
				return;
			}
			setCallStartTimeHHMMSS({
				callStartTime1: convertToHHMMSS(
					callLines?.call_lines[0]?.started_at ? callLines?.call_lines[0]?.started_at : 0,
				),
				callStartTime2: convertToHHMMSS(
					callLines?.call_lines[1]?.started_at ? callLines?.call_lines[1]?.started_at : 0,
				),
			});
		}, 1000);
		return () => {
			durationTimmer.current = false;
			clearInterval(intervalId);
			setCallStartTimeHHMMSS({ callStartTime1: "", callStartTime2: "" });
		};
	}, [callLines]);
	useEffect(() => {
		const extensionPresence = extensionPresenceChanged?.filter((item: IPresence) => {
			return extensionData?.subscriber?.extension_id === item.extension_id ? true : false;
		});
		if (extensionPresence.length) {
			const persence = extensionPresence[0];
			setCallLines(persence);
		}
	}, [extensionPresenceChanged]);
	useEffect(() => {
		const extensionStatus = extensionStatusChanged?.filter((item: any) => {
			return item?.user_id === extensionData?.user?.id ? true : false;
		});
		if (extensionStatus.length) {
			const status = extensionStatus[0];
			setStatusResult(status?.main_status ? status?.main_status : "");
			setAdditionalStatusResult(status?.additional_status ? status?.additional_status : "");
		}
	}, [extensionStatusChanged]);
	const updateStatus = () => {
		const userStatus = {
			userID: extensionData?.user?.id,
			main_status: statusResult,
			additional_status: additionalStatusResult,
		};
		console.log();
		dispatch(updateShowStatusMenu({ setStatusByUser: true, userStatus: userStatus }));
		// dispatch(setShowSidecarSidebar(false));
	};
	const queueUpdateError = (message: string) => {
		showToast(`Queue ${message} failed, please try again later`, "error");
	};
	const queueUpdateSuccess = (message: string) => {
		queue?.allQueuesRefresh();
		showToast(`Queue ${message} successful`, "success");
	};
	const queueLogoutClick = ({ extensionID, queueID }: { extensionID: string; queueID: string }) => {
		if (!queueID || !extensionID) {
			queueUpdateError("logout");
			return;
		}
		const fetch = async () => {
			try {
				const data = `[{"queue_id": "${queueID}","extension_ids": ["${extensionID}"]}]`;
				const result = await queueLogout(data);
				if (result.status === "fulfilled") {
					queueUpdateSuccess("logout");
				} else {
					queueUpdateError("logout");
				}
			} catch (error) {
				queueUpdateError("logout");
			}
		};
		fetch();
	};
	const activeCallLine = (forTransfer?: boolean) => {
		const extensionNumber = String(extensionData?.data?.extension);
		for (let x = 0; x < ringingInboundCalls?.length; x++) {
			if (ringingInboundCalls[x]?.DisplayNumber === extensionNumber) {
				return { lineNumber: ringingInboundCalls[x]?.LineNumber };
			}
		}
		for (let x = 0; x < answeredCalls?.length; x++) {
			if (forTransfer || answeredCalls[x]?.DisplayNumber === extensionNumber) {
				return { lineNumber: answeredCalls[x]?.LineNumber, state: "answered" };
			}
		}
		for (let x = 0; x < ringingOutboundCalls?.length; x++) {
			if (ringingOutboundCalls[x]?.DisplayNumber === extensionNumber) {
				return { lineNumber: ringingOutboundCalls[x]?.LineNumber };
			}
		}
		return undefined;
	};
	useEffect(() => {
		const extensionNumber = String(extensionData?.data?.extension);
		let answeredCall = false;
		for (let x = 0; x < answeredCalls?.length; x++) {
			answeredCall = true;
			break;
			// if(answeredCalls[x]?.DisplayNumber === extensionNumber){
			// 	answeredCall = true;
			// }
		}
		if (answeredCall === false) {
			setTransferBtn(false);
		}
	}, [answeredCalls]);
	return (
		<div
			className={styles.mainCard}
			onMouseOut={() => {
				setIsCardHover(false);
			}}
			onMouseOver={() => {
				setIsCardHover(true);
			}}
			title={`${extensionData?.name}`}>
			<div
				className={`${styles.card} ${
					extensionData?.presence_status === null
						? styles.empity
						: callLines?.status === "on_the_phone"
						? styles.orange
						: styles.green
				}`}>
				{/* ${styles.green}  */}
				<div className={styles.header}>
					<div
						className={`${styles.dotIndication} ${
							statusResult === "do_not_disturb"
								? styles.bgR
								: statusResult === "away"
								? styles.bgG
								: statusResult === null
								? styles.bgW
								: ""
						} `}></div>
					<div className={styles.label}>{extensionData?.extension}</div>
					<div className={`${styles.label} ${styles.label_name}`}>
						<span>{extensionData?.name}</span>
					</div>
					<div className={styles.statusBlock}>
						{additionalStatusResult === "on_a_call" && <OnCallIcon />}
						{additionalStatusResult === "in_a_meeting" && <CalenderIcon />}
						{additionalStatusResult === "lunch" && <OnLunchIcon />}
						{additionalStatusResult === "holiday" && <HolidayIcon />}
						{additionalStatusResult === "afk" && <AFKIcon />}
					</div>
					{isCardHover && activeCallLine()?.lineNumber === undefined && (
						<div className={styles.callBtn}>
							<BtnAction
								btnType={"normal"}
								isDisabled={false}
								type="button"
								isActive={false}
								onMouseOut={() => {
									setIsCallHover(false);
								}}
								onMouseOver={() => {
									setIsCallHover(true);
								}}
								onClick={handleCall}
								icon={<PhoneIcon color={isCallHover ? "primary-default" : "icon-primary"} />}
							/>
						</div>
					)}
					{isCardHover && activeCallLine()?.lineNumber !== undefined && (
						<div className={styles.callHangUpBtn}>
							<BtnAction
								btnType={"normal"}
								isDisabled={false}
								type="button"
								isActive={false}
								onMouseOut={() => {
									setIsCallHover(false);
								}}
								onMouseOver={() => {
									setIsCallHover(true);
								}}
								onClick={() => {
									sip.hungup(activeCallLine()?.lineNumber);
								}}
								icon={<HangupIcon />}
							/>
						</div>
					)}
					{!(activeCallLine()?.lineNumber !== undefined) &&
						isCardHover &&
						activeCallLine(true)?.lineNumber !== undefined &&
						activeCallLine(true)?.state === "answered" && (
							<div className={`${styles.callTransfer} ${isCallTransferHover ? styles.isCallTransferHover : ""}`}>
								<BtnAction
									btnType={"normal"}
									isDisabled={false}
									type="button"
									isActive={false}
									onMouseOut={() => {
										setIsCallTransferHover(false);
									}}
									onMouseOver={() => {
										setIsCallTransferHover(true);
									}}
									onClick={() => {
										setTransferBtn(true);
									}}
									icon={<CallTransferIcon answered={true} />}
								/>
							</div>
						)}
					{isCardHover && statusResult !== null ? (
						<div
							className={`${styles.svgIcon} ${activeCallLine()?.lineNumber !== undefined ? styles.svgIcon_active : ""}`}
							onClick={() => {
								updateStatus();
							}}>
							<EditPenIcon />{" "}
						</div>
					) : (
						""
					)}
					{/* {isCardHover && <div className={styles.svgIcon} onClick={()=>{queueLoginClick({extensionID:extensionData?._id, queueID:queueID?queueID:""})}}>{queueID && extensionData?._id ? <Login/> : ""}</div>} */}
					{isCardHover && queueID && extensionData?._id && queue?.queueMember?.membership === "dynamic" ? (
						<div
							className={styles.svgIcon}
							onClick={() => {
								queueLogoutClick({ extensionID: extensionData?._id, queueID: queueID ? queueID : "" });
							}}>
							<Logout />{" "}
						</div>
					) : (
						""
					)}
					<div className={styles.voicemail}></div>
				</div>
				{!(activeCallLine()?.lineNumber !== undefined) &&
				showTransferBtn &&
				activeCallLine(true)?.lineNumber !== undefined &&
				activeCallLine(true)?.state === "answered" ? (
					<div className={styles.transferCard}>
						<TransferCallCard
							number={extensionData?.data?.extension}
							transferBtn={setTransferBtn}
							LineNumber={activeCallLine()?.lineNumber}
						/>
					</div>
				) : (
					""
				)}

				<div className={styles.lines}>
					<div className={styles.line}>
						<div className={`${styles.lineIcon} ${styles.svgCallLineStatusReady}`}>
							{extensionData?.presence_status === null ? (
								""
							) : line1 &&
							  (callLines?.call_lines[0]?.direction === "outbound" ||
									callLines?.call_lines[0]?.direction === "inbound") ? (
								callLines?.call_lines[0]?.direction === "outbound" ? (
									<CallerOutboundIcon />
								) : (
									<CallerInboundIcon />
								)
							) : (
								<CallerReadyIcon />
							)}
						</div>
						<div className={`${styles.label} ${styles.labelLeft}`}>
							{line1}
							<span style={{ float: "right", width: "60px" }}>{line1 ? callStartTimeHHMMSS.callStartTime1 : ""}</span>
						</div>
					</div>
					<div className={styles.line}>
						<div className={`${styles.lineIcon} ${styles.svgCallLineStatusReady}`}>
							{extensionData?.presence_status === null ? (
								""
							) : line1 &&
							  (callLines?.call_lines[1]?.direction === "outbound" ||
									callLines?.call_lines[1]?.direction === "inbound") ? (
								callLines?.call_lines[1]?.direction === "outbound" ? (
									<CallerOutboundIcon />
								) : (
									<CallerInboundIcon />
								)
							) : (
								<CallerReadyIcon />
							)}
						</div>
						<div className={`${styles.label} ${styles.labelLeft}`}>
							{line2}
							<span style={{ float: "right", width: "60px" }}>{line2 ? callStartTimeHHMMSS.callStartTime2 : ""}</span>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ExtensionCard;
