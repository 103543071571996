import { isRejectedWithValue } from "@reduxjs/toolkit";
import axios from "axios";
import Cookies from "js-cookie";
import { setSessionOut } from "redux/common/commonSlice";
import { store } from "redux/store";

export const jwtTokenRefresher =
	({ dispatch }: Record<any, any>) =>
	(next: any) =>
	async (action: any) => {
		if (action && isRejectedWithValue(action)) {
			if (localStorage.getItem("extAuth") !== "true") {
				if (action?.payload?.response?.status === 401) {
					await axios
						.post("https://b2clogin.beta.ringplan.com/refresh-token", {
							access_token: Cookies.get("id_token"),
							refresh_token: Cookies.get("refresh_token"),
						})
						.then((resp) => {
							const expirationDate = new Date("9999-12-31T23:59:59Z");

							Cookies.set("id_token", resp?.data?.id_token, { expires: expirationDate });
							Cookies.set("id_token", resp?.data?.id_token, { domain: "/", expires: expirationDate });
							Cookies.set("id_token", resp?.data?.id_token, { domain: ".beta.ringplan.com", expires: expirationDate });
							Cookies.set("id_token", resp?.data?.id_token, { domain: ".ringplan.com", expires: expirationDate });
							Cookies.set("refresh_token", resp?.data?.refresh_token, { expires: expirationDate });
							Cookies.set("refresh_token", resp?.data?.refresh_token, { domain: "/", expires: expirationDate });
							Cookies.set("refresh_token", resp?.data?.refresh_token, {
								domain: ".beta.ringplan.com",
								expires: expirationDate,
							});
							Cookies.set("refresh_token", resp?.data?.refresh_token, {
								domain: ".ringplan.com",
								expires: expirationDate,
							});

							// var formattedExpiryDate = "Fri, 31 Dec 9999 23:59:59 GM";
							// document.cookie = `id_token=${resp?.data?.id_token}; domain=.ringplan.com; path=/; expires=${formattedExpiryDate}`;
							// document.cookie = `refresh_token=${resp?.data?.refresh_token}; domain=.ringplan.com; path=/; expires=${formattedExpiryDate}`;
							window.location.reload();
						})
						.catch((e) => {
							store.dispatch(setSessionOut(true));
						});
				}
			} else {
				store.dispatch(setSessionOut(false));
			}
		}

		return next(action);
	};
