import Sidebar from "../../components/shared/Sidebar";
import styles from "./BaseLayout.module.scss";
import ProgressCallPopUpBar from "../../components/Dashboard/ProgressCallPopup";
import NotificationMsg from "components/Notification";
import { useDispatch, useSelector } from "react-redux";
import {
	badgeCounter,
	notification,
	refreshVersion,
	sessionOut,
	updates,
	version,
	showSurveyModal,
	showWarningModal,
} from "redux/common/commonSelectors";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useRef, useState } from "react";
import { store } from "redux/store";
import BottomNav from "components/shared/BottomNav";
import InboundCall from "components/shared/InboundCall";
import { ToastContainer } from "react-toastify";
import ModalMessage from "components/shared/ModalMessage";
import { getCookie } from "typescript-cookie";
import { getBackendUrl } from "config/env.config";
import { io } from "socket.io-client";
import { setSocket, setUnreadMessageCount } from "redux/chat/chatSlice";
import { socket, unreadMessageCount } from "redux/chat/chatSelectors";
import OpenApp from "components/shared/OpenApp";
import MessagePopUpBar from "components/Dashboard/MessagePopUpBar";
import alertSound from "../../assets/media/Alert.mp3";
import SidecarSidebar from "components/Sidecar/SidecarSidebar";
import { showSidecarSidebar, sidecarConfig } from "redux/sidecar/sidecarSelectors";
import { useLazyModifySidecarConfigQuery, useLazyNewSidecarConfigQuery } from "services/storage";
import { saveSidecarConfigToLocalStorage, sidecarUploadFromServer } from "utils/sidecar/sidecarXML";
import useChatAPI from "hooks/useChatAPI";
import { useLazyGetStatusQuery } from "services/status";
import { useLazyGetAccountDetailsQuery } from "services/account";
import { useLazySetStatusQuery } from "services/status";
import StatusMenu from "components/Profile/StatusMenu";
import Sidecar from "components/Dashboard/Sidecar";
import axios from "axios";
import { setRefreshVersion, setShowSurveyModal } from "redux/common/commonSlice";
import UpdateAvailable from "components/UpdateAvailable";
import {
	useLazyCreateCallHistoryFileQuery,
	useLazyGetCallHistoryFileQuery,
	useLazyUpdateCallHistoryFileQuery,
} from "services/call";
import { showToast } from "utils";
import { setCallHistory } from "redux/call-history/callHistorySlice";
import { ANSWERED, CANCEL } from "constants/sipCallStatuses";
import { organizeHistoryArray } from "helpers";
import { setBadge } from "utils/badge";
import { setNewAdding } from "redux/call/callSlice";
import useIsAppInstalled from "hooks/useIsInstalled";
import useInstallPrompt from "hooks/useInstallPrompt";
import callEndingSound from "./../../assets/media/end_call_sound.mp3";
import WarningModal from "components/WarningModal";
import SurveyModal from "components/SurveyModal";

let Socket: any = null;

const BaseLayout = ({ children }: any) => {
	const dispatch = useDispatch();

	const { navigatePush, micPermission, sipRegistrationStatus } = useSelector((state: any) => state.sip);
	const sendMessageSidecar = useSelector((state: any) => state.sidecar.sendMessage);
	const uploadToServer = useSelector((state: any) => state.sidecar.uploadToServer);
	const updateStatusSidecar = useSelector((state: any) => state.sidecar.updateStatus);
	const showStatusMenu = useSelector((state: any) => state.sidecar.showStatusMenu);
	const { answeredCalls, ringingOutboundCalls, userId, extAuth, callEnding, ringingInboundCalls, extNumber } =
		useSelector((state: any) => state.sip);
	const dispNotification = useSelector(notification);
	const sessionValid = useSelector(sessionOut);
	const reudxSocket = useSelector(socket);
	const unreadMessage = useSelector(unreadMessageCount);
	const showSidecarSidebarState = useSelector(showSidecarSidebar);
	const [getStatus] = useLazyGetStatusQuery();
	const [getAccountDetails] = useLazyGetAccountDetailsQuery();
	const [updateStatus] = useLazySetStatusQuery();
	const [modifySidecarConfig] = useLazyModifySidecarConfigQuery();
	const [newSidecarConfig] = useLazyNewSidecarConfigQuery();
	const navigate = useNavigate();
	const location = useLocation();
	const currentVersion = useSelector(version);
	const currentUpdates = useSelector(updates);
	const [updateAvailable, setUpdateAvailable] = useState(false);
	const isRefreshVersion = useSelector(refreshVersion);
	const [getCallHistoryFile] = useLazyGetCallHistoryFileQuery();
	const [createCallHistoryFile] = useLazyCreateCallHistoryFileQuery();

	let isPageVisible = !document.hidden;

	const [hideUpdate, setHideUpdate] = useState(false);
	const { error, isLoading, response, sendMessage } = useChatAPI();
	const { status } = useSelector((state: any) => state.sip);
	const [tempOutboundCall, setTempOutboundCall] = useState<any>([]);
	const [tempInboundCall, setTempInboundCall] = useState<any>([]);
	const [updateCallHistoryFile] = useLazyUpdateCallHistoryFileQuery();
	const [isInstalled, setIsInstalled] = useState(true);
	const installed = useIsAppInstalled();
	const deferredPrompt = useInstallPrompt();
	const audioRef = useRef(null);
	const isShowWarningModal = useSelector(showWarningModal);
	const isShowSurveyModal = useSelector(showSurveyModal);
	const [endingCallInfo, setEndingCallInfo] = useState<any>();

	const handleInstallClick = async () => {
		if (deferredPrompt) {
			deferredPrompt.prompt();
			const { outcome } = await deferredPrompt.userChoice;
			if (outcome === "accepted") {
				setIsInstalled(true);
				localStorage.setItem("installed", "1");
			}
		}
	};

	useEffect(() => {
		if (deferredPrompt) {
			setIsInstalled(installed || Boolean(localStorage.getItem("install")));
			if (localStorage.getItem("install") !== null) {
				setIsInstalled(Boolean(localStorage.getItem("install")));
			} else {
				setIsInstalled(installed);
			}
		}
	}, [installed, deferredPrompt]);

	const saveCallHistory = async (
		number: string,
		direction: string,
		duration: string,
		status: string,
		name?: string,
	) => {
		dispatch(setNewAdding(true));
		const key = `ext_${extNumber}`;
		const historyParsed = (await JSON.parse(String(localStorage.getItem("recent")))) || {};

		let contact: any = {};
		let contacts = [];

		try {
			contacts = await JSON.parse(String(localStorage.getItem("contacts")));
		} catch {
			contacts = [];
		}

		if (contacts?.length) {
			[contact] = contacts?.filter((ct: any) => ct?.phone == number);
		}

		const newData = {
			contact: {
				id: contact?.id || "",
				name:
					contact?.first_name || contact?.last_name
						? contact?.first_name && contact?.first_name + " " + contact?.last_name && contact?.last_name
						: name
						? name
						: number,
			},
			cdr: {
				id: historyParsed?.[key]?.data?.[0]?.id ? historyParsed?.[key]?.data?.[0]?.id + 1 : 1,
				billsec: duration,
				duration: duration,
				dst: direction == "Inbound" ? `${extNumber}` : `${number}`,
				src: direction == "Outbound" ? `${extNumber}` : `${number}`,
				starttime: new Date(Date.now()).toISOString(),
				status: status,
			},
		};

		const updatedHistory = {
			meta: {
				...historyParsed?.meta,
				updated_at: new Date().toISOString(),
				total: historyParsed?.meta?.total + 1,
			},
			data: {
				...historyParsed?.data,
				[key]: {
					...historyParsed?.data?.[key],
					data: [newData, ...(historyParsed?.data?.[key]?.data || [])],
				},
			},
		};

		const stringUpdatedHistory = JSON.stringify(updatedHistory);
		localStorage.setItem("recent", stringUpdatedHistory);

		const updateCallHistory = async () => {
			const blob = new Blob([stringUpdatedHistory], { type: "application/json" });
			const formData = new FormData();
			formData.append("upfile", blob, `recent_${userId}.json`);
			formData.append("unique_name", `recent_${userId}`);

			const { data, error } = await updateCallHistoryFile(formData);

			if (data) {
				const organizedData = organizeHistoryArray(updatedHistory?.data?.[`ext_${extNumber}`]?.data, extNumber);
				dispatch(setCallHistory(organizedData?.slice(0, 20)));
				// dispatch(setCallHistory(updatedHistory?.data?.[`ext_${extNumber}`]?.data));
			} else {
				//
			}

			dispatch(setNewAdding(false));
		};

		setTempInboundCall("");
		setTempInboundCall("");
		updateCallHistory();
	};

	useEffect(() => {
		if (!tempOutboundCall?.length && ringingOutboundCalls?.length && !answeredCalls?.length) {
			setTempOutboundCall(ringingOutboundCalls);
		} else if (
			tempOutboundCall?.length &&
			!ringingOutboundCalls?.length &&
			!callEnding?.length &&
			!answeredCalls?.length
		) {
			saveCallHistory(tempOutboundCall?.[0]?.DisplayNumber, "Outbound", "00:00", CANCEL);
		}

		if (!tempInboundCall?.length && ringingInboundCalls?.length) {
			setTempInboundCall(ringingInboundCalls);
		}
	}, [ringingOutboundCalls, ringingInboundCalls]);

	useEffect(() => {
		if (callEnding?.length) {
			saveCallHistory(
				callEnding?.[0]?.number,
				callEnding?.[0]?.direction,
				callEnding?.[0]?.callTimer,
				ANSWERED,
				callEnding?.[0]?.name,
			);

			if (audioRef.current) {
				audioRef.current.play();
			}
		}
	}, [callEnding, ringingInboundCalls, ringingOutboundCalls]);

	const handleVisibilityChange = () => {
		if (localStorage.getItem("version")) {
			if (document.visibilityState === "hidden") {
				axios
					.get("manifest.json", {
						headers: {
							"Cache-Control": "no-cache",
							Pragma: "no-cache",
							Expires: "0",
						},
					})
					.then((resp) => {
						if (localStorage.getItem("version") != resp?.data?.version) {
							setUpdateAvailable(true);
							dispatch(setRefreshVersion(true));
						}
					});
			}
		}
	};

	useEffect(() => {
		document.addEventListener("visibilitychange", handleVisibilityChange);

		// uncomment the code below to reset saved recent
		// const updatedHistory = {};

		// const stringUpdatedHistory = JSON.stringify(updatedHistory);

		// const updateCallHistory = async () => {
		// 	const blob = new Blob([stringUpdatedHistory], { type: "application/json" });
		// 	const formData = new FormData();
		// 	formData.append("upfile", blob, `recent_${localStorage.getItem("userId")}.json`);
		// 	formData.append("unique_name", `recent_${localStorage.getItem("userId")}`);

		// 	const { data, error } = await updateCallHistoryFile(formData);

		// 	if (data) {
		// 		localStorage.setItem("recent", stringUpdatedHistory);
		// 		const organizedData = organizeHistoryArray(updatedHistory?.data?.[`ext_${extNumber}`]?.data, extNumber);
		// 		dispatch(setCallHistory(organizedData?.slice(0, 20)));
		// 		// dispatch(setCallHistory(updatedHistory?.data?.[`ext_${extNumber}`]?.data));
		// 	} else {
		// 		//
		// 	}
		// };

		// updateCallHistory();

		// steps
		// 1. try to download the recent file
		// 2.1. recent file doesn't exist
		//		2.1.1. create the file with an empty structure
		//		2.1.2. upload that file to the server
		//		2.1.3. save the data to local storage under "recent"
		//		2.1.4. save the data to global state that will control both dashboard & recent sidebar
		// 2.2. recent file exists
		//		2.2.1. parse the file and save it to local storage
		//		2.2.2. save the data to a global variable
	}, [userId]);

	const statusUpdateSidecar = ({
		status,
		additional_status,
		sidecar,
	}: {
		status: string;
		additional_status?: string;
		sidecar?: { uuid: string; actionUUID: string; result?: "Done" | "Fail" };
	}) => {
		if (status == "") return;
		if (additional_status == "" || additional_status == null || additional_status == undefined) {
			let userStatusMainStatus = status;
			store.dispatch({
				type: "sip/status",
				payload: {
					main_status: userStatusMainStatus,
					additional_status: additional_status,
				},
			});
		}
		const sendData =
			additional_status != ""
				? {
						main_status: {
							status: status,
						},
						additional_status: {
							status: additional_status,
							action_type: "manual",
							action: "set",
						},
				  }
				: {
						main_status: {
							status: status,
						},
				  };
		if (sidecar) {
			const res = { uuid: sidecar.uuid, actionUUID: sidecar.actionUUID, result: "" };
			updateStatus(JSON.stringify(sendData))
				.then(() => {
					res.result = "Done";
				})
				.catch(() => {
					res.result = "Fail";
				})
				.finally(() => {
					store.dispatch({ type: "sidecar/executeNextSidecarAction", payload: res });
				});
		}
	};
	useEffect(() => {
		if (navigatePush !== "") {
			navigate(navigatePush);
			store.dispatch({ type: "sip/navigatePush", payload: "" });
		}
	}, [navigatePush]);

	useEffect(() => {
		if (localStorage.getItem("version")) {
			if (localStorage.getItem("version") != currentVersion) {
				localStorage.setItem("version", currentVersion);
				// setUpdateAvailable(true);
			}
		} else {
			localStorage.setItem("version", currentVersion);
		}
	}, [currentVersion]);

	useEffect(() => {
		if (sendMessageSidecar) {
			console.log(sendMessageSidecar);
			sendMessage({
				phone: sendMessageSidecar.to,
				msg: sendMessageSidecar.message,
				texting_number: sendMessageSidecar.from,
			});
			const sidecar = {
				uuid: sendMessageSidecar.sidecar.uuid,
				actionUUID: sendMessageSidecar.sidecar.actionUUID,
				result: "Done", //Message result "Done", "Fail"
			};
			store.dispatch({ type: "sidecar/executeNextSidecarAction", payload: sidecar });
			store.dispatch({ type: "sidecar/sendMessage", payload: undefined });
		}
	}, [sendMessageSidecar]);
	useEffect(() => {
		if (extAuth !== false || userId === undefined || !(status.main_status === "" && status.additional_status === ""))
			return;
		const fetchData = async () => {
			try {
				const { data } = await getStatus(userId);
				if (data && data[0]) {
					const newStatus = {
						additional_status: data[0]?.additional_status?.status ? data[0].additional_status.status : "",
						main_status: data[0]?.main_status?.status ? data[0].main_status.status : "",
					};
					store.dispatch({ type: "sip/status", payload: newStatus });
					localStorage.setItem(
						"user_data",
						JSON.stringify({ email: data[0]?.user_email, id: data[0]?.user_id, company_id: data[0]?.company_id }),
					);
				}
			} catch (err) {}
		};
		fetchData();
	}, [userId]);

	useEffect(() => {
		if (extAuth !== false || userId !== undefined) return;
		const fetchData = async () => {
			try {
				const { data } = await getAccountDetails(userId);
				if (data) {
					store.dispatch({ type: "sip/userId", payload: data.id });
					localStorage.setItem("userId", data.id);
				}
			} catch (err) {}
		};
		fetchData();
	}, []);
	useEffect(() => {
		if (uploadToServer) {
			const xmlString = localStorage.getItem("sidecarConfig");
			if (xmlString) {
				const blob = new Blob([xmlString], { type: "application/xml" });
				const formData = new FormData();
				formData.append("upfile", blob, "sidecarConfig.xml");
				formData.append("unique_name", `sidecar_${localStorage.getItem("userId")}`);
				const uploadError = (error: any) => {
					//error message
				};
				const uploadData = async () => {
					try {
						const result = await modifySidecarConfig(formData);
						if (result.status === "fulfilled") {
							const lastSyncStamp = new Date().getTime();
							localStorage.setItem("lastSync", String(lastSyncStamp));
							store.dispatch({ type: "sidecar/setLastSync", payload: lastSyncStamp });
						} else {
							const result = await newSidecarConfig(formData);
							if (result.status === "fulfilled") {
								const lastSyncStamp = new Date().getTime();
								localStorage.setItem("lastSync", String(lastSyncStamp));
								store.dispatch({ type: "sidecar/setLastSync", payload: lastSyncStamp });
							} else {
								uploadError(result);
							}
						}
					} catch (error) {
						uploadError(error);
					}
				};
				uploadData();
			}
			store.dispatch({ type: "sidecar/uploadToServer", payload: undefined });
		}
	}, [uploadToServer]);
	useEffect(() => {
		if (updateStatusSidecar) {
			const fromStatuses = updateStatusSidecar.fromStatuses;
			const toStatus: string =
				updateStatusSidecar.toStatus[0] === "do_not_disturb_local" ? "do_not_disturb" : updateStatusSidecar.toStatus[0];
			const sidecar = updateStatusSidecar.sidecar;
			const fail = (sidecar: { uuid: any; actionUUID: any }) => {
				const res = { uuid: sidecar.uuid, actionUUID: sidecar.actionUUID, result: "Fail" };
				store.dispatch({ type: "sidecar/executeNextSidecarAction", payload: res });
			};
			if (updateStatusSidecar?.action === "main_status") {
				if (fromStatuses.indexOf(status.main_status) !== -1) {
					statusUpdateSidecar({ status: toStatus, additional_status: "", sidecar: sidecar });
				} else {
					fail(sidecar);
				}
			} else if (updateStatusSidecar?.action === "additional_status") {
				if (fromStatuses.indexOf(status.additional_status) !== -1) {
					statusUpdateSidecar({ status: status.main_status, additional_status: toStatus, sidecar: sidecar });
				} else {
					fail(sidecar);
				}
			} else {
				fail(sidecar);
			}
			store.dispatch({ type: "sidecar/updateStatus", payload: undefined });
		}
	}, [updateStatusSidecar]);

	// socket.io
	useEffect(() => {
		const API_KEY = getCookie("id_token");

		if (Socket != null) {
			Socket.disconnect();
			Socket.offAny();
		}

		Socket = io(getBackendUrl(), {
			path: "/ws",
			transports: ["websocket"],
			secure: true,
			autoConnect: false,
			reconnectionDelay: 1500,
		});

		Socket.on("connect", () => {
			Socket.emit("authenticate", { token: API_KEY });
		});

		Socket.on("authenticated", (data) => {
			console.log("web socket Authentication successful.", data);

			// Extract necessary information from Socket object
			// const { connected, recovered, receiveBuffer, sendBuffer, _queue } = Socket;

			// Dispatch a serializable object with the necessary information
			// dispatch(setSocket({ connected, recovered, receiveBuffer, sendBuffer, _queue }));

			dispatch(setSocket(Socket));
		});

		Socket.connect();

		// Clean-up function to disconnect the socket when component unmounts
		// return () => {
		// 	socket.disconnect();
		// };
	}, []);

	useEffect(() => {
		if (!reudxSocket || !reudxSocket.connected) return;

		reudxSocket.on("texting.message.new", (data) => {
			console.log("texting.message.new base", data);

			// if ("serviceWorker" in navigator) {
			// 	console.log("in service worker");
			// 	navigator.serviceWorker.ready.then((serviceWorker) => {
			// 		console.log("in service worker 2 ");
			// 		serviceWorker.showNotification("New Message", {
			// 			body: "Marvin says: Hello",
			// 			silent: false,
			// 			sound: "./media/Alert.mp3",
			// 			actions: [
			// 				{
			// 					action: "reply",
			// 					type: "text",
			// 					title: "Reply",
			// 					placeholder: "Respond to Marvin...",
			// 				},
			// 			],
			// 		});
			// 	});
			// }

			if (data.direction === "outbound") {
				return;
			}

			const first_name = data?.contact?.first_name;
			const last_name = data?.contact?.last_name;
			const phone = data?.contact?.number;

			let firstName: string;
			let lastName: string;

			if (first_name === undefined || first_name === null) {
				firstName = "";
			} else {
				firstName = first_name;
			}

			if (last_name === undefined || last_name === null) {
				lastName = "";
			} else {
				lastName = last_name;
			}
			const name =
				data?.conversation_type === "group" || data?.conversation_type === "campaign"
					? data?.campaign_info?.name
					: firstName + lastName
					? firstName + " " + lastName
					: phone;

			if (location.pathname !== "/texting") {
				//update the unread count every time hit this condition 'unreadMessage' is coming from useSelector
				const unreadCount = unreadMessage;
				dispatch(setUnreadMessageCount(unreadCount + 1));

				// Browser Window Notification
				try {
					// if (isPageVisible) {
					// 	return; // Don't display the notification if the page is visible
					// }
					if ("Notification" in window) {
						if (Notification.permission === "granted") {
							let noticeOptions = {
								body: data?.text,
							};
							let inComingMesssgeNotification = new Notification(`New message from < ${name} >`, noticeOptions);

							const audio = new Audio();
							audio.src = alertSound;
							audio.play();

							// inComingCallNotification.onclick = function (event) {

							//   var lineNo = lineObj.LineNumber;
							//   var videoInvite = lineObj.SipSession.data.withvideo
							//   window.setTimeout(function () {
							//     // https://github.com/InnovateAsterisk/Browser-Phone/issues/26
							//     if (videoInvite) {
							//       AnswerVideoCall(lineNo)
							//     }
							//     else {
							//       AnswerAudioCall(lineNo);
							//     }
							//   }, 1000);

							//   // Select Buddy
							//   SelectLine(lineNo);
							//   return;
							// }

							inComingMesssgeNotification.onclick = function () {
								store.dispatch({ type: "texting/navigatePush", payload: "/texting" });
								window.focus();
							};
						}
					}
				} catch (error) {
					console.log(error);
				}
			} else {
				try {
					if (isPageVisible) {
						return; // Don't display the notification if the page is visible
					}
					if ("Notification" in window) {
						if (Notification.permission === "granted") {
							let noticeOptions = {
								body: data?.text,
							};
							let inComingMesssgeNotification = new Notification(`New message from < ${name} >`, noticeOptions);
							// Add sound to the notification

							const audio = new Audio();
							audio.src = alertSound;
							audio.play();

							inComingMesssgeNotification.onclick = function () {
								store.dispatch({ type: "texting/navigatePush", payload: "/texting" });

								window.focus();
							};
						}
					}
				} catch (error) {
					console.log(error);
				}
			}
		});
	}, [reudxSocket, unreadMessage, location.pathname, isPageVisible]);

	useEffect(() => {
		if (callEnding.length && !ringingInboundCalls?.[0]?.DisplayNumber && !ringingOutboundCalls?.[0]?.DisplayNumber) {
			setEndingCallInfo(callEnding?.[0]);
			dispatch(setShowSurveyModal(true));
		}
	}, [callEnding, ringingOutboundCalls, ringingInboundCalls]);

	return (
		<div className={`${styles.wrapper}`}>
			{isShowWarningModal ? <WarningModal msg="WARNING: ATTACHMENT LARGER THEN 1MB MAY FAIL TO DELIVER" /> : null}
			{isShowSurveyModal && endingCallInfo?.number ? (
				<SurveyModal name={endingCallInfo?.name} number={endingCallInfo?.number} />
			) : null}

			<audio ref={audioRef}>
				<source src={callEndingSound} type="audio/mp3" />
			</audio>
			{!isInstalled ? (
				<div className={styles.installPopup}>
					<h1>Welcome to RingPlan</h1>

					<p>
						You can enhance your experience by installing our website as an app. This allows you to access our content
						quickly and easily. Would you like to install RingPlan on your device?
					</p>

					<div className={styles.installBtns}>
						<button className={styles.installBtns_install} onClick={() => handleInstallClick()}>
							Install Now
						</button>
						<button
							className={styles.installBtns_close}
							onClick={() => {
								localStorage.setItem("install", "0");
								setIsInstalled(true);
							}}>
							Close
						</button>
					</div>
				</div>
			) : null}

			<OpenApp />
			<Sidecar />
			<ToastContainer />

			{updateAvailable && isRefreshVersion && <UpdateAvailable />}
			{/* {updateAvailable ? (
				<div className={styles.updates}>
					<span
						onClick={() => {
							setUpdateAvailable(false);
							dispatch(setRefreshVersion(false));
							setHideUpdate(true);
						}}></span>
					{!isRefreshVersion ? (
						<div className={styles.updatesRefresh}>
							<header>
								<h3>Version {currentVersion} updates:</h3>
								<button
									onClick={() => {
										setUpdateAvailable(false);
										setRefreshVersion(false);
									}}>
									<XIcon />
								</button>
							</header>
							<ul>
								{currentUpdates?.map((update) => (
									<li>- {update}</li>
								))}
							</ul>

							<div className={styles.updateBtn}>
								<button
									onClick={() => {
										setUpdateAvailable(false);
										setRefreshVersion(false);
									}}
									className={styles.updateBtn_cancel}>
									Close
								</button>
							</div>
						</div>
					) : (
						<div className={styles.updatesRefresh}>
							<header>
								<h3>Updates are available</h3>
								<button
									onClick={() => {
										setUpdateAvailable(false);
										setRefreshVersion(false);
										setHideUpdate(true);
									}}>
									<XIcon />
								</button>
							</header>
							<p>
								We've made adjustments to RingPlan behind the scenes. When you have a moment, refresh this tab to
								explore the newest version of RingPlan.
							</p>

							<div className={styles.updateBtn}>
								<button
									onClick={() => {
										setUpdateAvailable(false);
										setRefreshVersion(false);
										setHideUpdate(true);
									}}
									className={styles.updateBtn_cancel}>
									Close
								</button>
								<button
									onClick={() => {
										window.location.reload();
									}}>
									Refresh
								</button>
							</div>
						</div>
					)}
				</div>
			) : null} */}
			{showStatusMenu && (
				<StatusMenu setStatusByUser={showStatusMenu.setStatusByUser} userStatus={showStatusMenu.userStatus} />
			)}
			{showSidecarSidebarState && <SidecarSidebar />}
			{sipRegistrationStatus.message === "Registered" && micPermission === "denied" && (
				<MessagePopUpBar message={"Please Allow Microphone"} icon={"mic"} />
			)}
			{sipRegistrationStatus.timeNow - sipRegistrationStatus.updateAt > 5000 &&
				sipRegistrationStatus.message !== "Registered" && (
					<MessagePopUpBar message={sipRegistrationStatus.message} color="var(--primary-default)" />
				)}
			{sessionValid && <ModalMessage />}
			{/* {suggestPortraitOnMobileModalShow && <SuggestPortraitOnMobileModal />} */}
			{dispNotification.msg.length ? <NotificationMsg /> : null}
			<div className={styles.popUp} id="notification_bar">
				{location.pathname !== "/dashboard" && answeredCalls.length + ringingOutboundCalls.length > 0 && (
					<ProgressCallPopUpBar />
				)}
			</div>
			<InboundCall />
			<div>
				<div className={styles.sidebar}>
					<Sidebar />
				</div>

				<div className={styles.bottomNav}>
					<BottomNav />
				</div>

				<div className={styles.children}>{children}</div>
			</div>
		</div>
	);
};

export default BaseLayout;
