import { useEffect, useState } from "react";
import styles from "./SendMessageDialogue.module.scss";
import Backdrop from "components/UI/Backdrop";
import MessageIcon from "components/UI/Icons/Sidecar/Message";
import CloseIcon from "components/UI/Icons/Close";
import ContactBookIcon from "components/UI/Icons/ContactBook";
import { useDispatch, useSelector } from "react-redux";
import { addSidecarConfigNewTempActionList, setShowChooseActions, setShowSendMessageDialogue } from "redux/sidecar/sidecarSlice";
import { fromContactLists } from "redux/chat/chatSelectors";
import { showToast } from "utils";
import { setFromContactLists, setFromNumberSelected } from "redux/chat/chatSlice";
import { useLazyGetTextingNumbersQuery } from "services/chat";
import GeneralContactDialog from "components/Modal/GeneralContactDialog";

const SendMessageDialogue = ({uuid="", fromNumber, toNumber, message}:{uuid:string, fromNumber?:string, toNumber?:string, message?:string}) => {
	const dispatch = useDispatch();
	const [to, setToNumber] = useState(toNumber?toNumber:"");
	const [from, setFromNumber] = useState(fromNumber);
	const [msg, setMsg] = useState(message);
	const fromContactList = useSelector(fromContactLists);
	const [showSelectContact, setShowSelectContact] = useState(false);
	const back = ()=>{
		dispatch(setShowSendMessageDialogue({uuid:"", show:false}));
	}
	const [getTextingNumber] = useLazyGetTextingNumbersQuery();

	useEffect(() => {
		const fetchData = async () => {
			const { error, data } = await getTextingNumber("");

			if (data) {
				dispatch(setFromContactLists(data));
				dispatch(setFromNumberSelected(data[0].number));
			}

			if (error) {
				showToast("There is error in fetching From Contact Lists, please try again later  ", "error");
			}
		};

		fetchData();
	}, [fromContactList.length === 0]);
	return (
		!showSelectContact?
		<>
			<Backdrop onCancel={()=>{dispatch(setShowSendMessageDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}} />
			<div className={styles.dialogueBox}>
				<h1 className={styles.topHeading}>
					<span>
						<MessageIcon />
						<span>Send Message</span>
					</span>

					<span className={styles.closeBox} onClick={()=>{dispatch(setShowSendMessageDialogue({uuid:"", show:false}));if(!uuid)dispatch(setShowChooseActions(true))}}>
						<CloseIcon />
					</span>
				</h1>

				<div className={styles.row}>
					<label>From</label>
					<select onChange={(e) => {setFromNumber(e.target.value);}}>
						{fromContactList?.map((item) => (
						<option value={item?.number} key={item.id} selected={item?.number === from}>
							{item?.number}
						</option>
					))}
					</select>
					
				</div>

				<div className={styles.row}>
					<label>To number</label>
					<input type="text" value={to} onChange={(e) => {setToNumber(e.target.value);}}/>
					<span onClick={()=>{setShowSelectContact(true)}}>
						<ContactBookIcon />
					</span>
				</div>

				<textarea rows={5} placeholder="Enter message" className={styles.textarea} value={msg} onChange={(e) => {setMsg(e.target.value);}} ></textarea>

				<div className={styles.btnBox}>
					<button 
						disabled={ to === "" || from === "" || msg === "" ? true:false}
						className={`${to === "" || from === "" || msg === "" ? styles.disabled:{}}`}
						onClick={()=>{dispatch(addSidecarConfigNewTempActionList({name:"SendMessage", uuid:uuid, fromNumber:from, toNumber:to, message:msg})); back()} }>{uuid ? "Update":"Add Action"}</button>
				</div>
			</div>
		</>:<GeneralContactDialog setNumber={setToNumber} setShowSelectContact={setShowSelectContact} />
	);
};

export default SendMessageDialogue;
