import { store } from "redux/store";
import { IResult, IValidateButtonResult } from "./sidecarXMLTypes";
import {
	IDialAction,
	ISendMessageAction,
	IPauseAction,
	ITransferAction,
	IConferenceAction,
	IMergeAction,
	IKeyPressAction,
	IHangupAction,
	IHoldAction,
	IModifyMainStatusAction,
	IModifyAditionalStatusAction,
	ISidecarAction,
} from "redux/sidecar/sidecarTypes";
import { showToast } from "utils";

const validateButton = (actionButton: Element) => {
	const name = actionButton.getAttribute("name");
	const extension = actionButton.getAttribute("extension");
	if (!name || typeof name !== "string") {
		return { isValidBtn: false };
	}
	// if (!extension ) {
	//   return {isValidBtn:false};
	// }
	let hasValidAction = false;
	const actionList: Array<
		| IDialAction
		| ISendMessageAction
		| IPauseAction
		| ITransferAction
		| IConferenceAction
		| IMergeAction
		| IKeyPressAction
		| IHangupAction
		| IHoldAction
		| IModifyMainStatusAction
		| IModifyAditionalStatusAction
	> = [];
	const sidecarAction: ISidecarAction = {
		uuid: "",
		tagName: actionButton.tagName,
		extension: extension ? extension : "",
		name: name,
		actionList: actionList,
	};
	for (let i = 0; i < actionButton.children.length; i++) {
		const child = actionButton.children[i];
		switch (child.nodeName) {
			case "DIAL":
				const dialNumber = child.getAttribute("number");
				if (!dialNumber) {
					return { isValidBtn: false };
				}
				hasValidAction = true;
				const dial: IDialAction = {
					number: dialNumber,
					uuid: "",
					name: "Dial",
				};
				actionList.push(dial);
				break;
			case "PAUSE":
				const pauseSeconds = child.getAttribute("seconds");
				if (!pauseSeconds) {
					return { isValidBtn: false };
				}
				hasValidAction = true;
				const pause: IPauseAction = {
					uuid: "",
					name: "Pause",
					seconds: pauseSeconds,
				};
				actionList.push(pause);
				break;
			case "KEYPRESS":
				const keyPressNumber = child.getAttribute("number");
				if (!keyPressNumber || !(/^[0-9]$/.test(keyPressNumber) || keyPressNumber === "*" || keyPressNumber === "#")) {
					return { isValidBtn: false };
				}
				hasValidAction = true;
				const keyPress: IKeyPressAction = {
					uuid: "",
					name: "KeyPress",
					number: keyPressNumber,
				};
				actionList.push(keyPress);
				break;
			case "HOLD":
				hasValidAction = true;
				const hold: IHoldAction = {
					uuid: "",
					name: "Hold",
				};
				actionList.push(hold);
				break;
			case "MERGE":
				hasValidAction = true;
				const merge: IMergeAction = {
					uuid: "",
					name: "Merge",
				};
				actionList.push(merge);
				break;
			case "HANGUP":
				hasValidAction = true;
				const hangup: IHangupAction = {
					uuid: "",
					name: "Hangup",
				};
				actionList.push(hangup);
				break;
			case "CONFERENCE":
				const numbers = child.getAttribute("numbers");
				hasValidAction = true;
				if (!numbers || typeof numbers !== "string") {
					return { isValidBtn: false };
				}
				const conference: IConferenceAction = {
					uuid: "",
					name: "Conference",
					numbers: numbers.split(","),
				};
				actionList.push(conference);
				break;
			case "TRANSFER":
				const number = child.getAttribute("number");
				if (!number) {
					return { isValidBtn: false };
				}
				hasValidAction = true;
				const transfer: ITransferAction = {
					uuid: "",
					name: "Transfer",
					transferType: "",
					number: number,
				};
				actionList.push(transfer);
				break;
			case "SEND_MESSAGE":
				const fromNumber = child.getAttribute("fromNumber");
				const toNumber = child.getAttribute("toNumber");
				const message = child.getAttribute("message");
				if (!fromNumber) {
					return { isValidBtn: false };
				}
				if (!toNumber) {
					return { isValidBtn: false };
				}
				if (!message || typeof message !== "string") {
					return { isValidBtn: false };
				}
				hasValidAction = true;
				const sendMessage: ISendMessageAction = {
					uuid: "",
					name: "SendMessage",
					fromNumber: fromNumber,
					toNumber: toNumber,
					message: message,
				};
				actionList.push(sendMessage);
				break;
			case "MODIFY_STATUS":
			case "MODIFY_MAIN_STATUS":
			case "MODIFY_ADDITIONAL_STATUS":
				const toggleMode = child.getAttribute("toggleMode");
				const fromStatuses = child.getAttribute("fromStatuses");
				const toStatus = child.getAttribute("toStatus");
				if (!toggleMode || !/^[01]$/.test(toggleMode)) {
					return { isValidBtn: false };
				}
				// compare fromStatuses and toStatus to match the real key from status map object later
				if (!fromStatuses || typeof fromStatuses !== "string") {
					return { isValidBtn: false };
				}
				if (!toStatus || typeof toStatus !== "string") {
					return { isValidBtn: false };
				}
				hasValidAction = true;
				if (child.nodeName === "MODIFY_MAIN_STATUS" || child.nodeName === "MODIFY_STATUS") {
					const mainStatus: IModifyMainStatusAction = {
						uuid: "",
						name: "ModifyMainStatus",
						fromStatuses: fromStatuses.split(","),
						toStatus: toStatus.split(","),
						toggleMode: toggleMode,
					};
					actionList.push(mainStatus);
				} else if (child.nodeName === "MODIFY_ADDITIONAL_STATUS") {
					const additionalStatus: IModifyAditionalStatusAction = {
						uuid: "",
						name: "ModifyAditionalStatus",
						fromStatuses: fromStatuses.split(","),
						toStatus: toStatus.split(","),
						toggleMode: toggleMode,
					};
					actionList.push(additionalStatus);
				}
				break;
		}
	}
	const result: IValidateButtonResult = {
		isValidBtn: hasValidAction,
	};
	if (result.isValidBtn) {
		result.sidecarAction = sidecarAction;
	}
	return result;
};

export const validateXMLStage1 = (xmlString: string) => {
	let result: IResult = {
		isValid: true,
	};
	var parser = new DOMParser();
	var xmlDoc = parser.parseFromString(xmlString, "text/xml");
	if (xmlDoc.getElementsByTagName("parsererror").length > 0) {
		result = {
			isValid: false,
			message: "The XML is not valid",
		};
		return result;
	}

	var root = xmlDoc.documentElement;

	if (root.nodeName !== "SidecarConfig") {
		result = {
			isValid: false,
			message: "Invalid xml file format, root element should be SidecarConfig",
		};
		return result;
	}

	let rootElements = root.querySelectorAll("*");
	const actionButtons = Array.from(rootElements).filter(function (el) {
		return el.tagName.startsWith("Button_");
	});

	if (actionButtons.length === 0) {
		result = {
			isValid: false,
			message: "Invalid xml file format, there should be at least one button",
		};
		return result;
	}
	result = {
		isValid: true,
		result: { actionButtons: actionButtons },
	};
	return result;
};
export const validateXML = (xmlString: string) => {
	const validXML = validateXMLStage1(xmlString);
	if (validXML?.isValid !== true) {
		return validXML;
	}
	const actionButtons = validXML.result?.actionButtons;
	const result: IResult = {
		isValid: true,
	};
	const sidecarAction: ISidecarAction[] = [];
	actionButtons?.forEach((actionButton) => {
		const buttonResult = validateButton(actionButton);
		let isValidBtn = buttonResult.isValidBtn;
		if (isValidBtn == false) {
			result.isValid = false;
			if (!result.message) {
				result.message = [];
			}
			if (typeof result.message === "object") {
				result.message.push(`Invalid configuration in ${actionButton.tagName}`);
			}
		} else {
			if (buttonResult.sidecarAction) {
				sidecarAction.push(buttonResult.sidecarAction);
			}
		}
	});
	if (actionButtons)
		result.result = {
			actionButtons: actionButtons,
			sidecarActionList: sidecarAction,
		};
	return result;
};
export const getXMLConfigurationsActionList = (xmlString: string) => {
	const xml = validateXML(xmlString);
	const result: IResult = {
		isValid: true,
	};
	if (!xml.isValid) {
		result.isValid = false;
		return result;
	}
	// const actionList = []
	// return actionList
};
export const importXMLFromFile = () => {
	let inputElement = document.createElement("input");
	inputElement.type = "file";
	inputElement.accept = ".xml";
	inputElement.style.display = "none";
	document.body.appendChild(inputElement);
	inputElement.click();
	inputElement.addEventListener("change", function () {
		if (this.files) {
			let file = this.files[0];
			let reader = new FileReader();

			reader.onload = function () {
				if (typeof this.result === "string") {
					if (!localStorage.getItem("sidecarConfig")) {
						saveSidecarConfigToLocalStorage(this.result);
						//Upload this to server
					}
					const xml = validateXML(this.result);
					if (xml.isValid) {
						console.log(xml.result?.sidecarActionList);
						xml.result?.sidecarActionList?.map((sidecarActio) => {
							store.dispatch({
								type: "sidecar/addSidecarActionFromFile",
								payload: sidecarActio,
							});
						});
						showToast("sidecar import configuration successful", "success");
					} else {
						showToast("Unsupported sidecar configuration file", "error");
					}
				}
			};
			reader.readAsText(file);
		}
	});
};

export const sidecarUploadFromServer = (xmlString: string) => {
	const xml = validateXML(xmlString);
	if (xml.isValid) {
		console.log(xml.result?.sidecarActionList);
		xml.result?.sidecarActionList?.map((sidecarActio) => {
			store.dispatch({
				type: "sidecar/addSidecarAction",
				payload: sidecarActio,
			});
		});
	}
};

export const sidecarExportToFile = () => {
	const xmlString = localStorage.getItem("sidecarConfig");
	if (xmlString) {
		const blob = new Blob([xmlString], { type: "application/xml" });
		const downloadLink = document.createElement("a");
		downloadLink.href = URL.createObjectURL(blob);
		downloadLink.download = "sidecarConfig.xml";
		document.body.appendChild(downloadLink);
		downloadLink.click();
		document.body.removeChild(downloadLink);
	} else {
		showToast("Error exporting sidecar configuration to server", "error");
	}
};
export const newXMLConfigTemplate = () => {
	return `<?xml version='1.0' encoding='utf-8'?>\n<SidecarConfig>\n</SidecarConfig>`;
};
export const ButtonIDExist = (xmlDoc: Document, buttonID: string = "Button_") => {
	return xmlDoc?.getElementsByTagName("SidecarConfig")[0]?.lastElementChild?.tagName.startsWith(buttonID)
		? true
		: false;
};
export const getNewButtonID = (xmlDoc: Document) => {
	if (ButtonIDExist(xmlDoc)) {
		const lastButtonID = xmlDoc?.getElementsByTagName("SidecarConfig")[0]?.lastElementChild?.tagName.slice(7);
		return `Button_${String(Number(lastButtonID ? lastButtonID : 0) + 1)}`;
	}
	return "Button_1";
};
export const convertToXML = (sidecarConfig: ISidecarAction, buttonID: string): HTMLElement => {
	const extension = sidecarConfig.extension;
	const name = sidecarConfig.name;
	const actionList = sidecarConfig.actionList;
	const generateTagName = (name: string): string => {
		switch (name) {
			case "SendMessage":
				return "SEND_MESSAGE";
			case "ModifyMainStatus":
				return "MODIFY_MAIN_STATUS"; //MODIFY_STATUS
			case "ModifyAditionalStatus":
				return "MODIFY_ADDITIONAL_STATUS";
			default:
				return name.toUpperCase();
		}
	};
	const generateAttribute = (
		action:
			| IDialAction
			| ISendMessageAction
			| IPauseAction
			| ITransferAction
			| IConferenceAction
			| IMergeAction
			| IKeyPressAction
			| IHangupAction
			| IHoldAction
			| IModifyMainStatusAction
			| IModifyAditionalStatusAction,
	): string => {
		switch (action.name) {
			case "Hangup":
				return ``;
			case "Hold":
				return action.active ? ` active="${action.active}"` : "";
			case "Dial":
				return ` number="${action.number}"`;
			case "KeyPress":
				return ` number="${action.number}"`;
			case "Merge":
				return action.active ? ` active="${action.active}"` : "";
			case "Pause":
				return ` seconds="${action.seconds}"`;
			case "Transfer":
				return ` transferType="${action.transferType}" number="${action.number}"`;
			case "ModifyAditionalStatus":
			case "ModifyMainStatus":
				return ` fromStatuses="${action.fromStatuses?.join(",")}" toStatus="${action.toStatus?.join(
					",",
				)}" toggleMode="${action.toggleMode}"`;
			case "SendMessage":
				return ` fromNumber="${action.fromNumber}" toNumber="${action.toNumber}" message="${action.message}"`;
			case "Conference":
				return ` numbers="${action.numbers?.join(",")}"`;
		}
	};
	const convertToXML = (buttonXmlString: string): HTMLElement => {
		const parser = new DOMParser();
		const buttonXmlDoc = parser.parseFromString(buttonXmlString, "text/xml");
		return buttonXmlDoc.documentElement;
	};
	let response = `<${buttonID} extension="${extension}" name="${name}">${actionList
		.map((action) => {
			return `\n  <${generateTagName(action.name)}${generateAttribute(action)}/>`;
		})
		.join("\n")}\n</${buttonID}>`;

	return convertToXML(response);
};
export const manipulateXML = ({
	xmlString,
	action,
}: {
	xmlString: string;
	action:
		| { type: "delete"; payload: { tagName: string } }
		| { type: "add"; payload: { sidecarConfig: ISidecarAction } }
		| { type: "update"; payload: { sidecarConfig: ISidecarAction } };
}) => {
	// xmlString = JSON.parse(xmlString)
	const parser = new DOMParser();
	const xmlDoc = parser.parseFromString(xmlString, "text/xml");
	const serializer = new XMLSerializer();
	const response: { xmlString: string; newButtonID?: string; buttonID?: string } = {
		xmlString: xmlString,
	};
	switch (action.type) {
		case "delete": {
			const elements = xmlDoc.getElementsByTagName(action.payload.tagName);
			for (let i = elements.length - 1; i >= 0; i--) {
				elements[i]?.parentNode?.removeChild(elements[i]);
			}
			response.xmlString = serializer.serializeToString(xmlDoc);
			break;
		}
		case "add": {
			response.newButtonID = getNewButtonID(xmlDoc);
			const buttonElement = convertToXML(action.payload.sidecarConfig, response.newButtonID);
			xmlDoc.documentElement.appendChild(xmlDoc.importNode(buttonElement, true));
			response.xmlString = serializer.serializeToString(xmlDoc);
			break;
		}
		case "update": {
			response.buttonID = action.payload.sidecarConfig.tagName;
			if (!response.buttonID) {
				response.buttonID = getNewButtonID(xmlDoc);
			}
			const buttonElement = convertToXML(action.payload.sidecarConfig, response.buttonID);
			const oldElement = xmlDoc.getElementsByTagName(response.buttonID)[0];
			if (oldElement) {
				xmlDoc.documentElement.replaceChild(xmlDoc.importNode(buttonElement, true), oldElement);
			} else {
				xmlDoc.documentElement.appendChild(xmlDoc.importNode(buttonElement, true));
			}
			response.xmlString = serializer.serializeToString(xmlDoc);
			break;
		}
	}
	return response;
};
export const saveSidecarConfigToLocalStorage = (xmlString: string): string => {
	xmlString = xmlString?.replaceAll("\n", "");
	xmlString = xmlString?.replaceAll(/>[\s]*</g, "><");
	xmlString = xmlString?.replaceAll("><", ">\n  <");
	xmlString = xmlString?.replaceAll("\n  </Button", "\n </Button");
	xmlString = xmlString?.replaceAll("\n  <Button", "\n <Button");
	xmlString = xmlString?.replaceAll("\n  <SidecarConfig", "\n<SidecarConfig");
	xmlString = xmlString?.replaceAll("\n  </SidecarConfig", "\n</SidecarConfig");
	xmlString = xmlString?.replaceAll(
		'  <?xml version="1.0" encoding="utf-8"?>',
		'<?xml version="1.0" encoding="utf-8"?>',
	);
	localStorage.setItem("sidecarConfig", xmlString);
	return xmlString;
};
