import { apiService } from "./api";

export const voicemailService = apiService.injectEndpoints({
	endpoints: (build) => ({
		getVoicemails: build.query({
			query: (queries: string) => ({
				method: "GET",
				url: `/pbx/v2/voicemails/messages?${queries}&sort=-time_received`,
			}),
		}),
		deleteVoicemail: build.query({
			query: (id) => ({
				method: "DELETE",
				url: `/pbx/v2/voicemails/messages/${id}`,
			}),
		}),
		deleteVoicemails: build.query({
			query: (data) => ({
				method: "POST",
				url: "/pbx/v2/voicemails/messages/bulk-delete",
				data,
			}),
		}),
		updateVoicemails: build.query({
			query: (data) => ({
				method: "PATCH",
				url: "/pbx/v2/voicemails/messages/bulk-update",
				data: {
					listened: true,
					message_ids: data,
				},
			}),
		}),
	}),
});

export const {
	useLazyGetVoicemailsQuery,
	useLazyDeleteVoicemailQuery,
	useLazyDeleteVoicemailsQuery,
	useLazyUpdateVoicemailsQuery,
} = voicemailService;
